.box-icon-container {
    position: relative; /* This ensures the dropdown is positioned relative to the box */
    display: inline-block;
  }
  
  .box-icon {
    width: 24px;
    height: 24px;
    border: 1px solid #555;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background: #fff;
    box-sizing: border-box;
  }
  
  .box-icon.disabled {
    cursor: default;
    opacity: 0.5;
  }
  
  .fill-menu {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    width: auto;
    min-width: 100px;
  }
  
  .fill-option {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .fill-option:hover {
    background: #f0f0f0;
  }
  
  .fill-option.selected {
    background: #e0e0e0;
    font-weight: bold;
  }
  
.app-container {
  font-family: sans-serif;
  color: #333;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  background: #fafafa;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.nav-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
  color: #555;
}
.nav-button:hover {
  background: #eee;
  border-radius: 4px;
}

.month-display {
  margin: 0 20px;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.month-selector {
  position: absolute;
  top: 30px;
  left: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 10;
  white-space: nowrap;
  padding: 4px 0;
  min-width: 140px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}

.month-option {
  padding: 8px 12px;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
}
.month-option:hover {
  background: #f0f0f0;
}

.tracking-table {
  width: 100%;
}

.table-header {
  display: grid;
  grid-template-columns: 80px 1fr 60px 24px 24px 24px 24px;
  align-items: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: 8px;
}

.day-header, .highlight-header, .weight-header {
  font-size: 14px;
  font-weight: bold;
}

.header-icon-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: help;
}
.header-icon-cell svg {
  font-size: 18px;
}

.table-body .row {
  display: grid;
  grid-template-columns: 80px 1fr 60px 24px 24px 24px 24px;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px 0;
  gap: 8px;
}

.table-body .row.today-row {
  background: #e6f0ff; 
  border-radius: 4px;
  padding: 8px 0; 
}

.day-cell {
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: baseline;
  gap: 5px;
  white-space: nowrap;
}

.weekday {
  font-weight: bold;
  width: 30px; 
  font-size: 14px;
}
.daynum {
  color: #888;
  width: 20px; 
  text-align: right;
  display: inline-block;
  font-size: 14px;
}

.highlight-cell {
  position: relative;
}

.highlight-input {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  padding: 2px 0;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid transparent;
}
.highlight-input:focus, .highlight-input:hover {
  border-bottom: 1px solid #ccc;
}

.weight-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.weight-input {
  width: 58px;
  text-align: left; /* flush left */
  border: none;
  background: transparent;
  outline: none;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid transparent;
}
.weight-input:focus, .weight-input:hover {
  border-bottom: 1px solid #ccc;
}

.icon-box-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Two-column analytics section */
.analytics-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 30px;
  padding: 20px 0;
}

.analytics-left {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.analytics-right {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.analytics-scores-container {
  display: flex;
  flex-direction: column;
}

.analytics-score-row {
  display: grid;
  grid-template-columns: 24px 100px auto;
  gap: 8px;
  align-items: center;
  margin-bottom: 5px;
}

.analytics-score-row.total-row {
  margin-top: 10px;
}

.analytics-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analytics-label {
  text-align: left;
  font-weight: 600;
}

.analytics-score {
  text-align: right;
  white-space: nowrap;
}

.analytics-graph {
  border: 1px solid #ccc;
  background: #fafafa;
}

.analytics-legend {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.analytics-legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.no-weight-data {
  font-size: 12px;
  color: #999;
  text-align: center;
  margin-top: 10px;
}

.analytics-weight-range {
  margin-top: 5px;
  font-size: 10px;
  color: #666;
  text-align: center;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .table-header {
    grid-template-columns: 60px minmax(40px,1fr) 50px 20px 20px 20px 20px;
    gap: 4px;
  }
  .table-body .row {
    grid-template-columns: 60px minmax(40px,1fr) 50px 20px 20px 20px 20px;
    gap: 4px;
  }

  .day-cell, .highlight-input, .weight-input {
    font-size: 13px;
  }

  .weekday, .daynum {
    font-size: 13px;
  }

  .analytics-section {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .analytics-left, .analytics-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .analytics-label {
    width: 80px;
  }
}
